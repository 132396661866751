import { Badge, Button, Dropdown, Menu, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  loadNotification,
  toggleNotificationStatus,
} from "../../../api/notification";
import { INotification } from "../../../interfaces/config.interface";
import { IPagination } from "../../../interfaces/policy.interface";
import { errorParser } from "../../../util/common.util";
import NotificationSkeleton from "../../NotificationSkeleton";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/notification.svg";
import motorcycleIcon from "../../../assets/images/motorbike.svg";
import privateVehicleIcon from "../../../assets/images/sports-car.svg";
import travelIcon from "../../../assets/images/passenger.svg";
import personalAccicentIcon from "../../../assets/images/healthy-care.svg";
import HomeIcon from "../../../assets/images/house.svg";
import AgricultureIcon from "../../../assets/images/cow.svg";
import phiIcon from "../../../assets/images/healthcare.svg";
import othersIcon from "../../../assets/images/others.svg";
import { useHistory } from "react-router";

function Notification() {
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const [notificationPagination, setnotificationPagination] =
    useState<IPagination>({
      current: 1,
      pageSize: 10,
      total: 0,
    });
  const [loadingNotification, setLoadingNotification] = useState(false);
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const handleProjectDropDownScroll = (e: any) => {
    if (loadingNotification) {
      return;
    }
    if (notificationPagination.total <= notificationList.length) {
      return;
    }
    const element = e.target;
    if (element.scrollTop >= element.scrollHeight - element.offsetHeight - 60) {
      setLoadingNotification(true);
      setTimeout(loadNotificationHandler, 500);
    }
  };

  const loadNotificationHandler = async () => {
    const [response, error] = await loadNotification(notificationPagination);
    if (response) {
      setNotificationList(notificationList.concat(response.data.data));
      setnotificationPagination({
        pageSize: 10,
        total: response.data.total,
        current: +response.data.page + 1,
      });
      setNotificationCount(+response.data.total_unread);
      setLoadingNotification(false);
    }
    if (error) {
      errorParser(error);
    }
  };

  const onNotificationClick = async (
    redirectionPath: any,
    id: string,
    readStatus: boolean
  ) => {
    const [response] = await toggleNotificationStatus(id);
    if (response) {
      !readStatus && setNotificationCount(notificationCount - 1);
    }
    const notificationListUpdated = [...notificationList].map(
      (notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            read_status: true,
          };
        }
        return { ...notification };
      }
    );
    setNotificationList(notificationListUpdated);
    history.push(redirectionPath);
  };

  const notificationItems = (
    <Menu
      className="notification__dropdown"
      onScroll={handleProjectDropDownScroll}
    >
      <div className="notification__header d__flex align__items__center ml__10">
        <Typography.Title level={5}>Notification</Typography.Title>
      </div>
      {notificationList.length === 0 && loadingNotification && (
        <NotificationSkeleton count={5} />
      )}
      {notificationList.map((notification, index) => {
        let imgPath;
        let className;
        let redirectionPath: any;
        if (
          notification?.AllPolicy?.class === "Motorcycle" ||
          notification?.AllPolicy?.class === "Electric Motorcycle"
        ) {
          imgPath = motorcycleIcon;
          className = "motorbike";
          redirectionPath = `/policies/create-policy/${
            notification?.AllPolicy?.class === "Motorcycle"
              ? "motorcycle"
              : "electric-motorcycle"
          }/${notification?.AllPolicy?.policy_id}`;
        } else if (notification?.AllPolicy?.class === "Personal Accident") {
          imgPath = personalAccicentIcon;
          redirectionPath = `/policies/create-policy/personal-accident/${notification?.AllPolicy?.policy_id}`;
          className = "personalAccident";
        } else if (notification?.AllPolicy?.class === "Private Vehicle") {
          imgPath = privateVehicleIcon;
          className = "privateVehicle";
          redirectionPath = `/policies/create-policy/private-vehicle/${notification?.AllPolicy?.policy_id}`;
        } else if (
          notification?.AllPolicy?.class === "Personal Health Insurance"
        ) {
          imgPath = phiIcon;
          className = "phi";
          redirectionPath = `/policies/create-policy/personal-health/${notification?.AllPolicy?.policy_id}`;
        } else if (notification?.AllPolicy?.class === "Travel Insurance") {
          imgPath = travelIcon;
          className = "travel";
          redirectionPath = `/policies/create-policy/travel/${notification?.AllPolicy?.policy_id}`;
        } else if (notification?.AllPolicy?.class === "Agriculture") {
          imgPath = AgricultureIcon;
          className = "agriculture";
          redirectionPath = `/policies/create-policy/agriculture/${notification?.AllPolicy?.policy_id}`;
        } else if (notification?.AllPolicy?.class === "Others") {
          imgPath = othersIcon;
          className = "others";
          redirectionPath = `/policies/create-policy/others/${notification?.AllPolicy?.policy_id}`;
        } else if (notification?.AllPolicy?.class === "Household") {
          imgPath = HomeIcon;
          className = "home";
          redirectionPath = `/policies/create-policy/home/${notification?.AllPolicy?.policy_id}`;
        } else {
          imgPath = motorcycleIcon;
          className = "motorbike";
          redirectionPath = `/policies/create-policy/${
            notification?.AllPolicy?.class === "Motorcycle"
              ? "motorcycle"
              : "electric-motorcycle"
          }/${notification?.AllPolicy?.policy_id}`;
        }
        return (
          <Menu.Item
            onClick={() =>
              onNotificationClick(
                redirectionPath,
                notification.id,
                notification.read_status
              )
            }
            key={index}
          >
            <div className="notification__list">
              <Avatar size={50} className={className}>
                <img src={imgPath} alt="" className="policy-img" />
              </Avatar>
              <div className="comment__section">
                {notification.notification}
                <div className="comment__time">
                  <Typography.Text type="secondary">
                    {moment(notification.createdAt).format("ll HH:mm")}
                  </Typography.Text>
                </div>
              </div>
              {!notification.read_status && <div className="dot"></div>}
            </div>
          </Menu.Item>
        );
      })}
      {notificationList.length < notificationPagination.total && (
        <NotificationSkeleton count={2} />
      )}
    </Menu>
  );

  useEffect(() => {
    loadNotificationHandler();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      overlay={notificationItems}
      placement="topCenter"
      arrow
      trigger={["click"]}
      overlayClassName="fix-dropdown"
    >
      <Button className=" icon-btn mr__30">
        <NotificationIcon />
        {notificationCount > 0 && <Badge count={notificationCount} />}
      </Button>
    </Dropdown>
  );
}

export default Notification;
