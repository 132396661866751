import axiosInstance from "../axios/axios";
import { IHttpRes } from "../interfaces/http.interface";
import {
  IDraftValidationLogic,
  IMotorcycleCalculation,
  IMotorcycleModelDetail,
} from "../interfaces/policy.interface";
import { getAuthToken } from "./../util/localStorage.util";

export const postMotorcyclePolicy = async (
  data: any,
  policyName: string
): Promise<[IHttpRes<IMotorcycleCalculation> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      `/policy/${policyName}-policy/save-draft`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getCalculationDataMotorcycle = async (
  data: {
    is_third_party: boolean;
    is_comprehensive: boolean;
    is_rsmdt: boolean;
    is_basic_premium: boolean;
  },
  id: string,
  policyName: string
): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.post(
      `/policy/${policyName}-policy/policy-calculation/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getMotorCycleManufacturerList = async (
  type: string
): Promise<[IHttpRes<string[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy-configuration/manufacture/manufacturer?class=${type}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getMotorCycleModelDetail = async (
  model: string
): Promise<[IHttpRes<IMotorcycleModelDetail[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy-configuration/manufacture/model?manufacture_name=${model}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const validatePolicyId = async (
  id: string,
  policyName: string
): Promise<[IHttpRes<IDraftValidationLogic> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/${policyName}-policy/validate-policyid/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getPremuimCalculation = async (
  id: string,
  policyName: string,
  payload: any
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      `/policy/${policyName}-policy/policy-calculation/${id}?mode=json`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getPolicyDetail = async (
  id: string,
  policyName: string
): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/${policyName}-policy/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getClaimDetail = async (id: string): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`/policy/claims/${id}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const createClaim = async (payload: any): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.post(`/policy/claims`, payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const updateClaim = async (
  payload: any,
  id: String
): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.put(`/policy/claims/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const updatePolicyDetail = async (
  data: any,
  id: string,
  policyName: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.put(`/policy/${policyName}-policy/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const updatePVPolicyDetail = async (
  data: any,
  id: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.put(`/policy/private-vehicle-policy/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const deletePolicyDocument = async (
  data: { filePath: string; policyId: string },
  policyName: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.delete(`${data.filePath}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const deleteClaimDocument = async (
  claimId: string,
  image_id: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.delete(`/policy/claims/${claimId}/delete/${image_id}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const deleteHomeDocument = async (
  data: { filePath: string; policyId: string; assetId: string },
  policyName: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.delete(data.filePath, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const getPHISumInsuredList = async (): Promise<
  [IHttpRes<number[]> | null, any]
> => {
  try {
    const response = await axiosInstance.get(
      `/policy-configuration/personal-health-policy/sum-insured-range`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};
