import { approvalRequest, getRenewalDetail, getFinancerList  } from "./../../../api/policy";
import {
  validatePolicyId,
  getPremuimCalculation,
} from "./../../../api/motorcyclePolicy";
import { AppDispatch } from "./../../store";
import {
  GET_PREMIUM_CALCULATION_JSON,
  LOAD_FINANCER_LIST,
  UPDATE_APPROVAL_REQUEST,
  VALIDATE_POLICY_ID,
  GET_RENEWAL_PREMIUM__JSON
} from "./policyActionTypes";

export const validatePolicy =
  (id: string, policyName: string, cb: Function) =>
  async (dispatch: AppDispatch) => {
    const [response, error] = await validatePolicyId(id, policyName);
    if (response) {
      dispatch({ type: VALIDATE_POLICY_ID, payload: response.data });
      cb(response);
    }
    if (error) {
      cb(error);
    }
  };

export const getPremiumCalculationJSON =
  (id: string, policyName: string, payload: any, cb: Function) =>
  async (dispatch: AppDispatch) => {
    const [response, error] = await getPremuimCalculation(
      id,
      policyName,
      payload
    );
    if (response) {
      dispatch({
        type: GET_PREMIUM_CALCULATION_JSON,
        payload: filterJSON(policyName, response),
      });
      cb();
    }
    if (error) {
      cb(error);
    }
  };

  export const getRenewalPremiumJson =
  (id: string, cb: Function) =>
  async (dispatch: AppDispatch) => {
    const [response, error] = await getRenewalDetail(
      id
    );
    if (response) {
      dispatch({
        type: GET_RENEWAL_PREMIUM__JSON,
        payload: response.data.data,
      });
      cb();
    }
    if (error) {
      cb(error);
    }
  };

const filterJSON = (policyName: string, response: any) => {
   return response.data;
};

export const loadFinancerList =
  (cb: Function) => async (dispatch: AppDispatch) => {
    const [response, error] = await getFinancerList();
    if (response) {
      dispatch({ type: LOAD_FINANCER_LIST, payload: response.data });
      cb();
    }
    if (error) {
      cb(error);
    }
  };

export const approvalRequestAction =
  (policyName: string, id: string, cb: Function) =>
  async (dispatch: AppDispatch) => {
    const [response, error] = await approvalRequest(id, policyName);
    if (response) {
      dispatch({ type: UPDATE_APPROVAL_REQUEST });
      cb();
    }
    if (error) {
      cb(error);
    }
  };
