import { Card, Layout, Skeleton } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React from "react";

function MainSkeleton() {
  return (
    <Layout className="main-skeleton">
      <Header>
        <Skeleton.Button className="d__flex"></Skeleton.Button>
        <Skeleton.Avatar
          size="large"
          active
          className="d__flex"
        ></Skeleton.Avatar>
      </Header>
      <Layout>
        <Sider>
          <Skeleton active></Skeleton>
        </Sider>
        <Content>
          <Card>
            <Skeleton />
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainSkeleton;
