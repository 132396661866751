import { Menu, Skeleton } from "antd";
import React from "react";

interface IProps {
  count: number;
}

function NotificationSkeleton({ count }: IProps) {
  return (
    <>
      {new Array(count).fill(1).map((item) => (
        <Menu.Item>
          <div className="notification__list">
            <Skeleton.Avatar active size={50} />
            <div className="comment__section ml__10 d__block">
              <div className="skeleton-container">
                <Skeleton.Button active />
              </div>
              <div className="mt__10 skeleton-container">
                <Skeleton.Button active className="short" />
              </div>
            </div>
          </div>
        </Menu.Item>
      ))}
    </>
  );
}

export default NotificationSkeleton;
