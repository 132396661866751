import { IHttpRes } from "./../interfaces/http.interface";
import { getAuthToken } from "./../util/localStorage.util";
import axiosInstance from "../axios/axios";
import { IPagination } from "./../interfaces/policy.interface";
import { INotificationResponse } from "../interfaces/config.interface";

export const loadNotification = async (
  pagination: IPagination
): Promise<[IHttpRes<INotificationResponse> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/notification?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const toggleNotificationStatus = async (
  id: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.put(`/notification/${id}/status`, null, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};
