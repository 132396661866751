import { getAuthToken } from "./../util/localStorage.util";
import axiosInstance from "../axios/axios";
import { IHttp } from "../interfaces/http.interface";
import {
  ILoginRequest,
  ILoginResponse,
  IUser,
} from "./../interfaces/auth.interface";

export const login = async (
  payload: ILoginRequest
): Promise<[IHttp<ILoginResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/individual/login", payload);
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getProfile = async (): Promise<[IHttp<IUser>, any]> => {
  try {
    const response = await axiosInstance.get("/individual/profile", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const signupIndividual = async (
  payload: any
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/register", payload);
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const verifyOTP = async (data: {
  email: string;
  otp: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/validate-otp", data);
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const setPassword = async (data: {
  username: string;
  password: string;
  password_confirmation: string;
}): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.post(
      "/individual/update-password",
      data
    );
    return [response, null];
  } catch (error) {
    return [false, error];
  }
};

export const reSendOTP = async (data: {
  email: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/resend-otp", data);
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const sendResetLink = async (data: {
  email: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/forgot-password", data);
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const resetPassword = async (data: {
  token: string;
  password: string;
  password_confirmation: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/reset-password", data);
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const verifyToken = async (token: string): Promise<[boolean, any]> => {
  try {
    const formData = new FormData();
    formData.append("token", token);
    await axiosInstance.post("/individual/validate-token", formData, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const resendOTPForPhoneNumberChange = async (): Promise<
  [boolean, any]
> => {
  try {
    await axiosInstance.post(
      "/individual/resend-otp-change-mobile-number",
      null,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};
