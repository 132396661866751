import { notification } from "antd";

export const openErrorNotification = (description: string, message: string) => {
  notification["error"]({
    message: message,
    description: description,
    duration: 4,
    // placement: "bottomRight",
  });
};

export const openSuccessNotification = (
  description: string,
  message: string
) => {
  notification["success"]({
    message: message,
    description: description,
    duration: 4,
    // placement: "bottomRight",
  });
};
