import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React from 'react';
import Sidenav from './sidenav';
import logo from '../assets/images/logo.png';
import darkLogo from '../assets/images/dark-logo.png';
import { useAppSelector } from '../store/reduxHooks';

interface IProps {
  hideDrawer: () => void;
  visible: boolean;
  onClose: () => void;
}

const Navdrawer = ({ visible, hideDrawer, onClose }: IProps) => {
  const { isDark } = useAppSelector(state => state.configReducer);
  return (
    <div>
      <Drawer placement="left" closable={false} onClose={onClose} visible={visible}>
        <div className="drawer__header nav-drawer">
          <div className="header__logo header-title">
            <img src={isDark ? darkLogo : logo} alt="" className={`${isDark ? 'dark' : ''}`} />
          </div>
          <Button onClick={hideDrawer} className="drawer__trigger">
            <ArrowLeftOutlined />
          </Button>
        </div>
        <Sidenav hideDrawer={hideDrawer} sidebarColllapse={false} />
      </Drawer>
    </div>
  );
};

export default Navdrawer;
