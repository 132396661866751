import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router";
import { mainRoutes } from "../routes/main.routes";
import {
  getProfileAction,
  getFaqAction,
} from "../store/actions/auth/authActionCreators";
import { loadFinancerList } from "../store/actions/policy/policyActionCreators";
import { useAppDispatch } from "../store/reduxHooks";
import { isUserLoggedIn } from "../util/auth.util";
import { errorParser } from "../util/common.util";
import NavHeader from "./header/header";
import MainSkeleton from "./MainSkeleton";
import Navdrawer from "./navdrawer";
import { openErrorNotification } from "./notification";
import Sidenav from "./sidenav";

const { Content } = Layout;

function MainLayout() {
  const [sidebarColllapse, setSidebarColllapse] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const dispatch: any = useAppDispatch();
  const [loadingProfie, setloadingProfie] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const hideDrawer = () => {
    setVisible(false);
  };

  const handleSidebarTrigger = () => {
    setSidebarColllapse(!sidebarColllapse);
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      setloadingProfie(true);
      dispatch(
        getProfileAction((err?: any) => {
          setloadingProfie(false);
          if (err) {
            openErrorNotification(errorParser(err), "Error");
          }
        })
      );
      dispatch(
        loadFinancerList((err?: any) => {
          if (err) {
            openErrorNotification(errorParser(err), "Error");
          }
        })
      );
      dispatch(
        getFaqAction((err?: any) => {
          if (err) {
            openErrorNotification(errorParser(err), "Error");
          }
        })
      );
    }
  }, [dispatch]);

  if (!isUserLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return loadingProfie ? (
    <MainSkeleton />
  ) : (
    <>
      <NavHeader
        sidebarColllapse={sidebarColllapse}
        handleSidebarTrigger={handleSidebarTrigger}
        onClose={onClose}
        showDrawer={showDrawer}
      />
      <div className="sidenav-container">
        <Sidenav sidebarColllapse={sidebarColllapse} />
      </div>
      <Navdrawer onClose={onClose} visible={visible} hideDrawer={hideDrawer} />
      <Content
        className={`page__wrapper ${sidebarColllapse ? "expanded" : ""}`}
      >
        <Switch>
          {mainRoutes}
          <Redirect to="/pagenotfound" />
        </Switch>
      </Content>
    </>
  );
}

export default MainLayout;
