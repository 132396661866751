import { POLICY_STATUS } from "../../../enum/enum";
import { IPolicyReducer } from "../../../interfaces/policy.interface";
import {
  GET_PREMIUM_CALCULATION_JSON,
  LOAD_FINANCER_LIST,
  UPDATE_APPROVAL_REQUEST,
  UPDATE_PREMIUM_CALCULATION_JSON,
  VALIDATE_POLICY_ID,
  GET_RENEWAL_PREMIUM__JSON,
} from "./policyActionTypes";

const initState: IPolicyReducer = {
  premiumCalculationPayload: null,
  premiumCalculationJSON: null,
  financerList: [],
  renewalPremiumJSON: null,
};

export const policyReducer = (
  state = initState,
  action: any
): IPolicyReducer => {
  switch (action.type) {
    case VALIDATE_POLICY_ID: {
      return {
        ...state,
        premiumCalculationPayload: action.payload,
      };
    }
    case GET_PREMIUM_CALCULATION_JSON: {
      return {
        ...state,
        premiumCalculationJSON: action.payload,
      };
    }
    case GET_RENEWAL_PREMIUM__JSON: {
      return {
        ...state,
        renewalPremiumJSON: action.payload,
      };
    }
    case UPDATE_PREMIUM_CALCULATION_JSON: {
      return {
        ...state,
        premiumCalculationPayload: state.premiumCalculationPayload && {
          ...state.premiumCalculationPayload,
          is_third_party: action.payload.is_third_party,
          is_comprehensive: action.payload.is_comprehensive,
          is_rsmdt: action.payload.is_rsmdt,
        },
      };
    }
    case LOAD_FINANCER_LIST: {
      return {
        ...state,
        financerList: action.payload,
      };
    }
    case UPDATE_APPROVAL_REQUEST: {
      return {
        ...state,
        premiumCalculationPayload: state?.premiumCalculationPayload
          ? {
              ...state.premiumCalculationPayload,
              status: POLICY_STATUS.SENT_FOR_APPROVAL,
            }
          : null,
      };
    }
    default:
      return state;
  }
};
