import { IAuthReducer } from "./../../interfaces/auth.interface";
import * as authActions from "../actions/auth/authActions";

const initState: IAuthReducer = {
  user: null,
  faqs: null,
};

export const authReducer = (state = initState, action: any): IAuthReducer => {
  switch (action.type) {
    case authActions.GET_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case authActions.GET_FAQ: {
      return {
        ...state,
        faqs: action.payload,
      };
    }
    case authActions.UPDATE_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    // case authActions.GET_NEWS: {
    //   return {
    //     ...state,
    //     news: action.payload
    //   };
    // }
    default: {
      return state;
    }
  }
};
