export const VALIDATE_POLICY_ID = "VALIDATE_POLICY_ID";

export const GET_PREMIUM_CALCULATION_JSON = "GET_PREMIUM_CALCULATION_JSON";

export const GET_RENEWAL_PREMIUM__JSON = "GET_RENEWAL_PREMIUM__JSON";

export const UPDATE_PREMIUM_CALCULATION_JSON =
  "UPDATE_PREMIUM_CALCULATION_JSON";

export const LOAD_FINANCER_LIST = "LOAD_FINANCER_LIST";

export const UPDATE_APPROVAL_REQUEST = "UPDATE_APPROVAL_REQUEST";
