import { getProfile } from "./../../../api/auth";
import * as authActions from "./authActions";
import { getFaq } from "../../../api/faq";
import { getNews } from "../../../api/news";

export const checkLogin = (isAuth: boolean) => {
  // some aync logic
  return {
    type: authActions.CHECK_TOKEN,
    isAuth: isAuth,
  };
};

export const getProfileAction = (cb: Function) => async (dispatch: any) => {
  const [response, error] = await getProfile();
  if (response) {
    dispatch({ type: authActions.GET_PROFILE, payload: response.data });
    cb();
  }
  if (error) {
    cb(error);
  }
};

export const getFaqAction = (cb: Function) => async (dispatch: any) => {
  const [response, error] = await getFaq();
  if (response) {
    dispatch({ type: authActions.GET_FAQ, payload: response.data });
    cb();
  }
  if (error) {
    cb(error);
  }
};

export const updateUserAction =
  (cb: Function, user: any) => async (dispatch: any) => {
    dispatch({ type: authActions.UPDATE_USER, payload: user });
    cb();
  };

// export const getNewsAction = (cb: Function) => async (dispatch: any) => {
//   const [response, error] = await getNews();
//   if (response) {
//     dispatch({ type: authActions.GET_NEWS, payload: response.data });
//     cb();
//   }
//   if (error) {
//     cb(error);
//   }
// };
