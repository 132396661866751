import { configReducer } from "./reducers/configReducer";
import { authReducer } from "./reducers/authReducer";
import { configureStore } from "@reduxjs/toolkit";
import { policyReducer } from "./actions/policy/policyReducer";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    policyReducer: policyReducer,
    configReducer: configReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
