import { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { LocalStorageKeys } from "../enum/enum";
import { SWITCH_THEME } from "../store/actions/config/configActionTypes";
import { useAppDispatch } from "../store/reduxHooks";

function ThemeLoader(props: any) {
  const { switcher, themes } = useThemeSwitcher();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const theme = localStorage?.getItem(LocalStorageKeys.THEME);
    if (theme) {
      switcher({ theme: theme === "dark" ? themes.dark : themes.light });
    } else {
      switcher({ theme: "light" });
      localStorage.setItem(LocalStorageKeys.THEME, "light");
    }
    dispatch({ type: SWITCH_THEME, theme: theme === "dark" ? true : false });
  }, [switcher, themes, dispatch]);

  return props.children;
}

export default ThemeLoader;
