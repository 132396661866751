import { getAuthToken } from './../util/localStorage.util';
import axiosInstance from '../axios/axios';
import { IHttp } from '../interfaces/http.interface';
import { IFaqs } from '../interfaces/auth.interface';
export const getFaq = async (): Promise<[IHttp<IFaqs>, any]> => {
  try {
    const response = await axiosInstance.get('/individual/faq', {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });

    return [response, null];
  } catch (error) {
    return [null, error];
  }
};
