import { ReactComponent as ClaimsLogo } from "../assets/images/icons/claims.svg";
import { ReactComponent as DashboardLogo } from "../assets/images/icons/dashboard.svg";
import { ReactComponent as FAQLogo } from "../assets/images/icons/faq.svg";
import { ReactComponent as CalculatorLogo } from "../assets/images/icons/calculator.svg";
import { ReactComponent as DescriptionLogo } from "../assets/images/icons/product-description.svg";
import { ReactComponent as RenewalLogo } from "../assets/images/icons/renewal.svg";
import { ReactComponent as ProfileLogo } from "../assets/images/icons/profile.svg";
import { ReactComponent as PolicyLogo } from "../assets/images/icons/send.svg";

// import { CheckCircleOutlined } from "@ant-design/icons";
import { NavLinks } from "../interfaces";

export const sideNavList: NavLinks[] = [
  {
    href: "/",
    icon: <DashboardLogo />,
    title: "Dashboard",
    i18nKey: "dashboard",
  },
  {
    href: "/profile",
    icon: <ProfileLogo />,
    title: "Profile",
    i18nKey: "profile",
  },
  {
    href: "/policies",
    icon: <PolicyLogo />,
    title: "Policies",
    i18nKey: "policies",
  },
  {
    href: "",
    icon: <ClaimsLogo />,
    title: "Claims",
    i18nKey: "claims",

    children: [
      {
        href: "/apply-claims",
        icon: <ClaimsLogo />,
        title: "Apply Claims",
        i18nKey: "apply_claims",
      },
      {
        href: "/claims",

        icon: <ClaimsLogo />,
        title: "Claims List",
        i18nKey: "my_claims",
      },
    ],
  },
  {
    href: "/renewals",
    icon: <RenewalLogo />,
    title: "Renewal",
    i18nKey: "renewal",
  },
  {
    href: "/calculator",
    icon: <CalculatorLogo />,
    title: "Calculator",
    i18nKey: "calculator",
  },
  {
    href: "/product-description",
    icon: <DescriptionLogo />,
    title: "Product Description",
    i18nKey: "product_description",
  },
  {
    href: "/faq",
    icon: <FAQLogo />,
    title: "Faq",
    i18nKey: "faq",
  },
  // {
  //   href: "/product-detail",
  //   icon: <ProductLogo />,
  //   title: "Product Detail",
  // },
];
