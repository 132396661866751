import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

export const authRouteList: IRouteItem[] = [
  {
    name: "Forgot password",
    path: "/reset-password",
    LazyComponent: lazy(() => import("../pages/Set-password/SetPassword")),
    exact: true,
  },
  {
    name: "Forgot password",
    path: "/set-password",
    LazyComponent: lazy(() => import("../pages/Set-password/SetPassword")),
    exact: true,
  },
  {
    name: "Reset password",
    path: "/reset-password-link",
    LazyComponent: lazy(() => import("../pages/Reset-link/Resetlink")),
    exact: true,
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    LazyComponent: lazy(() => import("../pages/PrivacyPolicy/PrivacyPolicy")),
  },
  {
    name: "Login",
    path: "/login",
    LazyComponent: lazy(() => import("../pages/Login/Login")),
    exact: true,
  },
  {
    name: "Signup",
    path: "/signup",
    LazyComponent: lazy(() => import("../pages/Signup/Signup")),
    exact: true,
  },
  {
    name: "OTP",
    path: "/otp",
    LazyComponent: lazy(() => import("../pages/OPT/OTP")),
    exact: true,
  },
  {
    name: "Page not found",
    path: "/pagenotfound",
    LazyComponent: lazy(() => import("../pages/Error/Pagenotfound")),
  },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, Key) =>
    routerDom.push(
      <Route
        key={Key}
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const authRoutes = parseRoutes(authRouteList);
