import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { NavLinks } from "../interfaces";
import { sideNavList } from "./sideNavList";
import { useTranslation } from "react-i18next";

interface IProps {
  sidebarColllapse: boolean;
  hideDrawer?: () => void;
}

const { SubMenu } = Menu;

function Sidenav({ sidebarColllapse, hideDrawer }: IProps) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [menuKeys, setMenuKeys] = useState({
    selectedKey: location.pathname,
    openKey: "/" + location.pathname.split("/")[1],
  });

  useEffect(() => {
    setMenuKeys((keys) => ({
      ...keys,
      selectedKey: location.pathname.split("/").slice(0, 2).join("/"),
    }));
  }, [location]);

  return (
    <div>
      <Sider trigger={null} collapsible collapsed={sidebarColllapse}>
        <Menu
          mode={sidebarColllapse ? "vertical" : "inline"}
          // openKeys={[menuKeys.openKey]}
          selectedKeys={[menuKeys.selectedKey]}
        >
          {sideNavList.map((navLink: NavLinks) => {
            if (navLink.children) {
              return (
                <SubMenu
                  icon={navLink.icon}
                  key={navLink.href}
                  title={t(`navList.${navLink.i18nKey}`)}
                >
                  {navLink.children.map((childrenLink: NavLinks) => (
                    <Menu.Item
                      onClick={() => hideDrawer && hideDrawer()}
                      key={childrenLink.href}
                      icon={childrenLink.icon}
                    >
                      <Link to={childrenLink.href}>
                        {t(`routes.${childrenLink.i18nKey}`)}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                onClick={() => {
                  history.push(navLink.href);
                  hideDrawer && hideDrawer();
                }}
                key={navLink.href}
                icon={navLink.icon}
              >
                <Link to={navLink.href}>{t(`navList.${navLink.i18nKey}`)}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}

export default Sidenav;
