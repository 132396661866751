import React from "react";
import { Route, Switch } from "react-router-dom";
import MainLayout from "../components/mainLayout";
import { authRoutes } from "./auth.routes";
import { guestRoutes } from "./guest.routes";

function Routes() {
  return (
    <Switch>
      {authRoutes}
      {guestRoutes}
      <Route component={MainLayout} />
    </Switch>
  );
}

export default Routes;
