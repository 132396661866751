import { IConfigReducer } from "../../interfaces/config.interface";
import { SWITCH_THEME } from "../actions/config/configActionTypes";

const initState: IConfigReducer = {
  isDark: false,
};

export const configReducer = (
  state = initState,
  action: any
): IConfigReducer => {
  switch (action.type) {
    case SWITCH_THEME: {
      return {
        ...state,
        isDark: action.theme,
      };
    }
    default: {
      return state;
    }
  }
};
