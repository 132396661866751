import { clearLocalStorage } from "./../util/localStorage.util";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    //Handle refresh token here
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      window?.location?.pathname !== "/login" &&
      window?.location?.pathname !== "/reset-password"
    ) {
      clearLocalStorage();
      window.location.href = "/login";
      return Promise.reject(error);
    }
    if (error.response.status === 306 && !originalRequest._retry) {
      originalRequest._retry = true;
      //do some stuff here........
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
