import { IPolicyCount } from "./../interfaces/policy.interface";
import axiosInstance from "../axios/axios";
import {
  IHttp,
  IHttpRes,
  IHttpResWithData,
} from "../interfaces/http.interface";
import {
  IPagination,
  IPoliciesList,
  ITransactionList,
} from "../interfaces/policy.interface";
import { getAuthToken } from "../util/localStorage.util";

export const submitPrivateVehiclePolicy = async (
  data: FormData
): Promise<[any, any]> => {
  try {
    let response = await axiosInstance.post(
      "/policy/private-vehicle-policy/save-draft",
      data,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [false, error];
  }
};

export const getCompulsoryAccess = async (data: any): Promise<[any, any]> => {
  try {
    let response = await axiosInstance.post(
      "/policy-configuration/get-compulsory-excess",
      data,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [false, error];
  }
};

export const getFinancerList = async (): Promise<
  [IHttpRes<{ bank_id: string; bank_name: string }[]> | null, any]
> => {
  try {
    const response = await axiosInstance.get("/policy-configuration/bank", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getPolicyList = async (
  pagination: IPagination
): Promise<[IHttpResWithData<IPoliciesList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-policies?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFilteredPolicyList = async (
  pagination: IPagination,
  data: any
): Promise<[IHttpResWithData<IPoliciesList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-policies?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        params: data,
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFamilyPolicyList = async (
  pagination: IPagination,
  type: string
): Promise<[IHttpResWithData<IPoliciesList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-policies?page=${pagination.current}&perPage=${pagination.pageSize}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getPolicyWording = async (): Promise<[IHttp<any>, any]> => {
  try {
    const response = await axiosInstance.get("/individual/policy-wording", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTNC = async (): Promise<[IHttp<any>, any]> => {
  try {
    const response = await axiosInstance.get("/tns?slug=public", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const toggleNotification = async (master_id: string): Promise<[IHttp<any>, any]> => {
  try {
    const response = await axiosInstance.put(`/policy/all-policies/${master_id}/toggle-notification`,{}, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTransactionList = async (
  pagination: IPagination
): Promise<[IHttpResWithData<ITransactionList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-transactions?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFilteredTransactionList = async (
  pagination: IPagination,
  data: any
): Promise<[IHttpResWithData<ITransactionList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-transactions?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        params: data,
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFamilyTransactionList = async (
  pagination: IPagination,
  type: string
): Promise<[IHttpResWithData<ITransactionList[]> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/all-transactions?page=${pagination.current}&perPage=${pagination.pageSize}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getPendingPolicyCount = async (): Promise<
  [IHttpRes<IPolicyCount> | null, any]
> => {
  try {
    const response = await axiosInstance.get(`/policy/policy-count`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const deleteNDCFile = async (data: {
  filePath: string;
  policyId: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.delete(data.filePath, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const approvalRequest = async (
  id: string,
  name: string
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post(`/policy/${name}/send-for-approval/${id}`, null, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const printPolicyBill = async (
  printType: string,
  id: string,
  alias: string
): Promise<[IHttpResWithData<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/policy/${id}/pdf?pdf_type=${printType}&alias=${alias}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const printRenewalPolicyBill = async (
  printType: string,
  id: string,
  alias: string,
  lang: string
): Promise<[IHttpResWithData<any> | null, any]> => {
  try {
    const response = await axiosInstance.get(
      `/renewals/pdf/${id}?pdf_type=${printType}&alias=${alias}&pdf_lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const uploadDocuments = async (
  files: any
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      `/policy/home-policy/upload-file`,
      files,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const uploadClaimDocuments = async (
  files: any
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    const response = await axiosInstance.post(
      `/policy/claims/upload-file`,
      files,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const sendForClaimApproval = async (
  id: string
): Promise<[IHttpRes<any> | null, any]> => {
  try {
    let body = {
      status: "waiting_for_approval",
    };
    const response = await axiosInstance.post(
      `policy/claims/${id}/update-status`,
      body,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getRenewalsList = async (
  pagination: IPagination,
  type?: string
): Promise<[any, any]> => {
  type = type === "all" ? undefined : type;
  try {
    const response = await axiosInstance.get(
      `/renewals?page=${pagination.current}&perPage=${pagination.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        params: {
          type,
        },
      }
    );
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getRenewalDetail = async (id: string): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`/renewals/${id}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getClaimPolicies = async (
  queryParams: any
): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`/policy/all-policies`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      params: queryParams,
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFodo = async (): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`/policy/getfodo`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getBranchList = async (): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`/branches`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};
