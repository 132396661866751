import {
  BulbOutlined,
  CloseOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Menu, Switch, Image } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import darkLogo from "../../assets/images/dark-logo.png";
import { updateUserAction } from "../../store/actions/auth/authActionCreators";
import profileAvatar from "../../assets/images/profile-avatar.png";
import { ReactComponent as HamburgerIcon } from "../../assets/images/icons/hamburger.svg";
import { ReactComponent as ThemeIcon } from "../../assets/images/icons/theme.svg";
import logo from "../../assets/images/logo.png";
import { LocalStorageKeys } from "../../enum/enum";
import { SWITCH_THEME } from "../../store/actions/config/configActionTypes";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { truncateWord, errorParser } from "../../util/common.util";
import { clearLocalStorage } from "../../util/localStorage.util";
import Notification from "./components/Notification";
import { loadImage } from "../../api/profile";
import { openErrorNotification } from "../notification";
import { ReactComponent as LanguageLogo } from "../../assets/images/icons/language.svg";

interface IProps {
  handleSidebarTrigger: () => void;
  onClose: () => void;
  showDrawer: () => void;
  sidebarColllapse: boolean;
}

const Navbar = (props: IProps) => {
  const history = useHistory();
  const { user } = useAppSelector((state) => state.authReducer);
  const { isDark } = useAppSelector((state) => state.configReducer);
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<{ profile: any[] }>({
    profile: [],
  });

  const [showResponsiveSearch, setShowResponsiveSearch] =
    useState<boolean>(false);

  const handleResponsiveSearch = () => {
    setShowResponsiveSearch(true);
  };

  const handleResponsiveSearchClose = () => {
    setShowResponsiveSearch(false);
  };

  const signOutHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(updateUserAction(() => {}, {}));
    clearLocalStorage();
    history.push("/login");
  };

  const themeSwitchHandler = (value: boolean) => {
    switcher({ theme: value ? themes.dark : themes.light });
    localStorage.setItem(LocalStorageKeys.THEME, value ? "dark" : "light");
    dispatch({ type: SWITCH_THEME, theme: value });
  };

  const toggeThemeHandler = () => {
    switcher({
      theme: currentTheme === themes.dark ? themes.light : themes.dark,
    });
    localStorage.setItem(
      LocalStorageKeys.THEME,
      currentTheme === themes.light ? "dark" : "light"
    );
    dispatch({ type: SWITCH_THEME, theme: !isDark });
  };

  const fullName = useMemo(() => {
    return truncateWord(
      (user?.first_name || "") +
        "  " +
        (user?.middle_name || "") +
        " " +
        (user?.last_name || ""),
      17
    );
  }, [user]);

  const loadImageHandler = async () => {
    if (user?.from_mobile || user?.is_new_web_user) {
      return documentPreloader([user?.profile_image_links[0]], "profile");
    }
    const [response, error] = await loadImage(user?.profile_image_links[0]);
    const ext = "img";
    let fileList: any = {
      url: URL.createObjectURL(
        new Blob([response?.data], { type: "application/pdf" })
      ),
      serverPath: user?.profile_image_links[0],
      extension: ext,
    };
    if (response) {
      documentPreloader([fileList], "profile");
    }
    if (error) {
      openErrorNotification(errorParser(error), "Error");
    }
  };

  const loadUploadedDocuments = useCallback(() => {
    try {
      if (user?.profile_image_links) {
        loadImageHandler();
      }
    } catch (error) {}
  }, [user]);

  const documentPreloader = (fileList: any[], type: string) => {
    setFiles((files) => {
      return {
        ...files,
        [type]: fileList,
      };
    });
  };

  useEffect(() => {
    loadUploadedDocuments();
  }, [loadUploadedDocuments]);

  const menuProfile = (
    <Menu className="profile__dropdown">
      <Menu.Item key="1">
        <Link to="/profile">
          <div className="avatar_container profile__section d__flex">
            {user?.profile_image_links ? (
              <Image
                src={
                  files?.profile[0]?.thumbUrl ??
                  files?.profile[0]?.url ??
                  files?.profile[0]
                }
                preview={false}
                className="mr__20"
                // onClick={() => previewDocument(file)}
              />
            ) : (
              <Avatar size="large">{user?.first_name?.charAt(0)}</Avatar>
            )}
            <div>
              <h4 className="user__name">{fullName}</h4>
              <span className="work__department">
                {truncateWord(user?.email, 20)}
              </span>
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/profile-link-request">
          <span className="link__icons">
            <ProfileOutlined />
          </span>
          {t("profileDropdown.profile_link_request")}
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/help-center">
          <span className="link__icons">
            <QuestionCircleOutlined />
          </span>
          {t("profileDropdown.help_center")}
        </Link>
      </Menu.Item>
      <MenuItem key="4">
        <span className="theme__switch__row">
          <BulbOutlined />
          {t("profileDropdown.dark_mode")}
          <Switch checked={isDark} onChange={themeSwitchHandler} />
        </span>
      </MenuItem>
      <Menu.Item key="5">
        <Link to="#" onClick={signOutHandler}>
          <span className="link__icons">
            <LogoutOutlined />
          </span>
          {t("profileDropdown.signout")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const languageMenu = (
    <Menu className="profile__dropdown">
      <MenuItem key="1" onClick={() => i18n.changeLanguage("en")}>
        <div>English</div>
      </MenuItem>
      <MenuItem key="2" onClick={() => i18n.changeLanguage("np")}>
        <div>Nepali</div>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="page__header">
      <div
        className={`header__logo  ${
          props.sidebarColllapse ? "shrinked" : ""
        } desktop`}
      >
        <img
          src={isDark ? darkLogo : logo}
          alt=""
          className={`${isDark ? "dark" : ""}`}
        />
      </div>
      <div className="header__main">
        <div
          className={`responsive__search ${showResponsiveSearch ? "show" : ""}`}
        >
          <div className="search__wrapper">
            <Button>
              <SearchOutlined />
            </Button>
            <Input placeholder="Basic usage" />
          </div>
          <Button
            onClick={handleResponsiveSearchClose}
            className="btn__close__search"
          >
            <CloseOutlined />
          </Button>
        </div>

        <Button
          className="btn__menu__toggle icon-btn"
          onClick={props.handleSidebarTrigger}
        >
          <HamburgerIcon />
        </Button>
        <Button
          onClick={props.showDrawer}
          className="btn__menu__toggle drawer__trigger icon-btn"
        >
          <HamburgerIcon />
        </Button>
        <ul className="navigation__right">
          <li className="display__lg">
            <Button onClick={handleResponsiveSearch}>
              <SearchOutlined />
            </Button>
          </li>
          <Notification />
          <Button onClick={toggeThemeHandler} className="icon-btn mr__30">
            <ThemeIcon />
          </Button>
          <Dropdown
            overlay={languageMenu}
            placement="bottomRight"
            arrow
            trigger={["click"]}
            overlayClassName="fix-dropdown"
          >
            <Button className=" icon-btn mr__30">
              <LanguageLogo />
            </Button>
          </Dropdown>
          <li className="avatar_container d__flex align__items__center">
            <Dropdown
              overlay={menuProfile}
              placement="bottomRight"
              arrow
              trigger={["click"]}
              overlayClassName="fix-dropdown"
            >
              {user?.profile_image_links ? (
                <Image
                  src={
                    files?.profile[0]?.thumbUrl ??
                    files?.profile[0]?.url ??
                    files?.profile[0]
                  }
                  preview={false}
                  className="mr__20"
                  // onClick={() => previewDocument(file)}
                />
              ) : (
                <Avatar size="large">{user?.first_name?.charAt(0)}</Avatar>
              )}
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
