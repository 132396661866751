import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

const policyRoute = (name: string, pageName: string) => {
  let route = {
    name: `${name.replaceAll("-", "_")}`,
    path: `/policies/create-policy/${name.toLowerCase()}`,
    LazyComponent: lazy(() => import(`../pages/${pageName}/${pageName}`)),
    exact: true,
  };
  return route;
};

const policyIdRoute = (name: string, pageName: string) => {
  let route = {
    name: `${name.replaceAll("-", "_")}`,
    path: `/policies/create-policy/${name.toLowerCase()}/:id`,
    LazyComponent: lazy(() => import(`../pages/${pageName}/${pageName}`)),
    exact: true,
  };
  return route;
};

// const othersPolicyRoute = (name: string, pageName: string) => {
//   let route = {
//     name: `${name.replaceAll("-", "_")}`,
//     path: `/policies/create-policy/others/:id`,
//     LazyComponent: lazy(() => import(`../pages/Others/Others`)),
//     exact: true
//   }
//   return route;
// }replaceAll

const calculationRoute = (name: string) => {
  let route = {
    name: `${name.replaceAll("-", "_")}`,
    path: `/policies/create-policy/${name.toLowerCase()}/:id/calculation`,
    LazyComponent: lazy(() => import("../pages/Calculation/Calculation")),
    exact: true,
  };
  return route;
};

const otherPremiumCalculationRoute = (name: string) => {
  let route = {
    name: `${name.replace("-", "_")}`,
    path: `/policies/create-policy/${name.toLowerCase()}/:id/premium`,
    LazyComponent: lazy(
      () => import("../pages/Others/components/OthersPaymentDetail")
    ),
    exact: true,
  };
  return route;
};

const paymentPage = (name: string) => {
  let route = {
    name: "payment",
    path: `/policies/create-policy/${name.toLowerCase()}/:id/calculation/payment`,
    LazyComponent: lazy(() => import("../pages/Payment/Payment")),
    exact: true,
  };
  return route;
};

const renewalPaymentPage = (name: string) => {
  let route = {
    name: "renewal_payment",
    path: `/renewal/${name.toLowerCase()}/:id/payment`,
    LazyComponent: lazy(() => import("../pages/Payment/Payment")),
    exact: true,
  };
  return route;
};

const premiumCalculator = (name: string) => {
  let route = {
    name: name.toLowerCase().replaceAll("-", "_"),
    path: `/calculator/${name.toLowerCase()}`,
    LazyComponent: lazy(
      () =>
        import(
          `../pages/${name.replaceAll(
            "-",
            ""
          )}PremiumCalculator/${name.replaceAll("-", "")}PremiumCalculator`
        )
    ),
    exact: true,
  };
  return route;
};

const premiumCalculationPage = (name: string) => {
  let route = {
    name: `${name.replaceAll("-", "_")}`,
    path: `/calculator/${name.toLowerCase()}/calculation`,
    LazyComponent: lazy(
      () => import("../pages/PremiumCalculation/PremiumCalculation")
    ),
    exact: true,
  };
  return route;
};

export const mainRoutesList: IRouteItem[] = [
  {
    name: "dashboard",
    path: "/",
    LazyComponent: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    name: "profile",
    path: "/profile",
    LazyComponent: lazy(() => import("../pages/Profile/Profile")),
    exact: true,
  },

  {
    name: "faq",
    path: "/faq",
    LazyComponent: lazy(() => import("../pages/FAQ/FAQ")),
    exact: true,
  },
  {
    name: "renewal",
    path: "/renewals",
    LazyComponent: lazy(() => import("../pages/Renewal/Renewal")),
  },

  {
    name: "profile_link_request",
    path: "/profile-link-request",
    LazyComponent: lazy(
      () => import("../pages/ProfileLinkRequest/ProfileLinkRequest")
    ),
    exact: true,
  },
  {
    name: "policies",
    path: "/policies",
    LazyComponent: lazy(() => import("../pages/Policies/Policies")),
    exact: true,
  },
  {
    name: "my_claims",
    path: "/claims",
    LazyComponent: lazy(() => import("../pages/Claims/Claims")),
    exact: true,
  },
  {
    name: "policy_claim",
    path: "/apply-claims",
    LazyComponent: lazy(() => import("../pages/ApplyClaims/ApplyClaims")),
    exact: true,
  },
  {
    name: "product_description",
    path: "/product-description",
    LazyComponent: lazy(
      () => import("../pages/ProductDescription/ProductDescription")
    ),
    exact: true,
  },
  {
    name: "help_center",
    path: "/help-center",
    LazyComponent: lazy(() => import("../pages/HelpCenter/HelpCenter")),
    exact: true,
  },
  {
    name: "kyc_form",
    path: "/update-kyc",
    LazyComponent: lazy(() => import("../pages/KYC/KYC")),
    exact: true,
  },
  {
    name: "kyc_form",
    path: "/kyc-details",
    LazyComponent: lazy(() => import("../pages/FamilyDetails/FamilyDetails")),
    exact: true,
  },
  {
    name: "update_kyc",
    path: "/kyc-details/:id",
    LazyComponent: lazy(() => import("../pages/FamilyDetails/FamilyDetails")),
    exact: true,
  },
  {
    name: "create_policy",
    path: "/policies/create-policy",
    LazyComponent: lazy(() => import("../pages/CreatePolicy/CreatePolicy")),
    exact: true,
  },
  policyRoute("motorcycle", "Motorcycle"),
  policyRoute("electric-motorcycle", "Motorcycle"),
  policyRoute("home", "HomeForm"),
  policyRoute("others", "Others"),
  policyRoute("private-vehicle", "PrivateVehicle"),
  policyRoute("travel", "Travel"),
  policyRoute("personal-accident", "PersonalAccident"),
  policyRoute("personal-health", "PersonalHealth"),
  // policyRoute("agriculture", "Agriculture"),
  policyRoute("iti", "InternationalTravelPolicy"),

  policyIdRoute("motorcycle", "Motorcycle"),
  policyIdRoute("electric-motorcycle", "Motorcycle"),
  policyIdRoute("home", "HomeForm"),
  policyIdRoute("others", "Others"),
  policyIdRoute("private-vehicle", "PrivateVehicle"),
  policyIdRoute("travel", "Travel"),
  policyIdRoute("personal-accident", "PersonalAccident"),
  policyIdRoute("personal-health", "PersonalHealth"),
  policyIdRoute("agriculture", "Agriculture"),
  policyIdRoute("iti", "InternationalTravelPolicy"),

  calculationRoute("motorcycle"),
  calculationRoute("electric-motorcycle"),
  calculationRoute("private-vehicle"),
  calculationRoute("travel"),
  calculationRoute("personal-accident"),
  calculationRoute("personal-health"),
  calculationRoute("agriculture"),
  calculationRoute("iti"),

  paymentPage("motorcycle"),
  paymentPage("electric-motorcycle"),
  paymentPage("private-vehicle"),
  paymentPage("travel"),
  paymentPage("iti"),
  paymentPage("personal-accident"),
  paymentPage("personal-health"),
  paymentPage("home"),
  paymentPage("agriculture"),
  paymentPage("others"),

  renewalPaymentPage("motorcycle"),
  renewalPaymentPage("electric-motorcycle"),
  renewalPaymentPage("private-vehicle"),
  renewalPaymentPage("travel"),
  renewalPaymentPage("personal-accident"),
  renewalPaymentPage("personal-health"),
  renewalPaymentPage("home"),
  renewalPaymentPage("agriculture"),
  renewalPaymentPage("iti"),
  renewalPaymentPage("others"),

  calculationRoute("home"),
  otherPremiumCalculationRoute("others"),
  {
    name: "payment",
    path: `/policies/create-policy/others/:id/payment`,
    LazyComponent: lazy(() => import("../pages/Payment/Payment")),
    exact: true,
  },
  {
    name: "payment_status",
    path: "/payment-status",
    LazyComponent: lazy(() => import("../pages/ResultPage/ResultPage")),
    exact: true,
  },
  {
    name: "payment_status",
    path: "/payment-failed",
    LazyComponent: lazy(() => import("../pages/ResultPage/ResultPage")),
    exact: true,
  },
  {
    name: "claims",
    path: `/claims`,
    LazyComponent: lazy(() => import("../pages/Claims/Claims")),
    exact: true,
  },
  {
    name: "policy_claim",
    path: `/policy/claims/:policyName/:id`,
    LazyComponent: lazy(() => import("../pages/Claims/Claims")),
    exact: true,
  },
  {
    name: "claims",
    path: `/claims/:policyName/:id`,
    LazyComponent: lazy(() => import("../pages/Claims/Claims")),
    exact: true,
  },
  {
    name: "claims",
    path: `/claims/:policyName`,
    LazyComponent: lazy(() => import("../pages/Claims/Claims")),
    exact: true,
  },
  {
    name: "package_comparison",
    path: "/package-comparison",
    LazyComponent: lazy(
      () => import("../pages/PackageComparison/PackageComparison")
    ),
  },

  {
    name: "calculator",
    path: "/calculator",
    LazyComponent: lazy(() => import("../pages/Calculator/Calculator")),
    exact: true,
  },

  premiumCalculator("Motorcycle"),
  premiumCalculator("Personal-Accident"),
  premiumCalculator("Private-Vehicle"),
  premiumCalculator("Home"),
  premiumCalculator("Travel"),
  premiumCalculator("Agriculture"),
  premiumCalculator("Personal-Health"),
  premiumCalculator("ITI"),
  {
    name: "electric_motorcycle",
    path: "/calculator/electric-motorcycle",
    LazyComponent: lazy(
      () =>
        import(
          "../pages/MotorcyclePremiumCalculator/MotorcyclePremiumCalculator"
        )
    ),
    exact: true,
  },

  premiumCalculationPage("motorcycle"),
  premiumCalculationPage("electric-motorcycle"),
  premiumCalculationPage("private-vehicle"),
  premiumCalculationPage("personal-accident"),
  premiumCalculationPage("home"),
  premiumCalculationPage("agriculture"),
  premiumCalculationPage("personal-health"),
  premiumCalculationPage("travel"),
  premiumCalculationPage("iti"),

  // portfolio.map((item) => othersPolicyRoute(item.toLowerCase(), item.toLowerCase().replaceAll(" ", "-")));
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, index) =>
    routerDom.push(
      <Route
        key={index}
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const mainRoutes = parseRoutes(mainRoutesList);
